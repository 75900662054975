import { CommonApiImplementation } from './CommonApiImplementation';
import { RENDER_END, RENDER_START } from '../components/counters/AD_LOAD_COUNTERS';
import * as AD_LOAD_EVENTS from '../components/events/AD_LOAD_EVENTS';
import type { AdDetails } from '../../@types/adCommon';

export type SetupVideoPlayer = (options: AdDetails, xmlContext: string) => void;

export const handleVideoExperience = (
    adDetails: AdDetails,
    c: CommonApiImplementation,
    xmlContent: string,
    setupOMVideoPlayer: SetupVideoPlayer,
): void => {
    try {
        c.countMetric(RENDER_START, 1);
        c.logCsaEvent(AD_LOAD_EVENTS.RENDER_START);

        setupOMVideoPlayer(adDetails, xmlContent);

        c.countMetric(RENDER_END, 1);
        c.logCsaEvent(AD_LOAD_EVENTS.RENDER_END);
    } catch (ex) {
        c.handleFallbackBehavior();
    }
};
