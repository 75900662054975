import type { AdDetails } from '../../@types/adCommon';
import { HostMessageToClient } from '../components/messaging/types';
import type { ClientApis } from './types/types';

export type ClientMessageListeners = { [key: string]: (data: any) => void };

export class ClientMessageReceiver {
    constructor(
        readonly c: ClientApis,
        readonly o: AdDetails,
        private readonly mL: ClientMessageListeners,
    ) {}
    private readonly pendingMessages = new Map<string, () => void>();
    // TODO Convert this to a more generalized mechanism
    // For now it just waits for the next message of the key to be processed
    // Main usecase is for dynamic compuated details to get the non-Promised APIS to work as in
    // https://code.amazon.com/packages/SafeFrameClient/blobs/e6d7afa4b0c567ab7ba245a07efd974cd8f3a6b6/--/packages/safe-frame-client-mobilesfclient/src/MobileSFClientAPI.ts#L187
    /**
     * This does not support more than one wait per message time and promise will only be resolved once
     * @deprecated
     * @param key The commadn to for wait for
     * @returns A promise that will be fired.
     */
    waitForMessageProcessed = async (key: string): Promise<void> => {
        return new Promise((resolve) => this.pendingMessages.set(key, resolve));
    };

    receiveMessage = (event: MessageEvent) => {
        try {
            const msg: HostMessageToClient = event.data;
            // msgObj is expected to have a command
            const cmdPath = msg.command ? msg.command.split('.') : [];
            type ActionPath = { [key: string]: ActionPath } | ((...args: any) => void);
            let action: ActionPath = this.c[cmdPath[0] as keyof ClientApis];
            for (let i = 1; i < cmdPath.length; i++) {
                if (action) {
                    action = (action as unknown as { [key: string]: ActionPath })[cmdPath[i]];
                } else {
                    break;
                }
            }
            if (msg.command === 'customMessage') {
                // treat custom message specially
                const msgKey = msg.data.key;
                const body = msg.data.data;
                if (typeof this.mL[msgKey] === 'function') {
                    try {
                        this.mL[msgKey](body);
                    } catch (ex) {
                        this.c.logError('Custom Message Listener Error', ex as Error);
                    }
                }
            } else if (action) {
                (action as (...args: any) => void)(msg.data);
                resolveMessagePromise(msg.command, this.pendingMessages);
            }
        } catch (ex) {
            //Log the error metrics for debugging purposes.
            this.c.logError('Error with ' + event.data.command, ex as Error);
            return;
        }
    };
}

const resolveMessagePromise = (command: string, pendingMessages: Map<string, () => void>) => {
    const resolve = pendingMessages?.get(command);
    resolve?.();
    pendingMessages?.delete(command);
};
