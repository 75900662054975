import { ClientMessageToHost } from '../components/messenger';
import type { AdDetails } from '../../@types/adCommon';
import type { CommonSupportedCommands } from '../host/CommonSupportedCommands';
import type { MobileSupportedCommands } from '../host/MobileSupportedCommands';
import type { DesktopSupportedCommands } from '../host/DesktopSupportedCommands';
import { errorLog } from './commonSf';

export type ToHostCommandType =
    | keyof CommonSupportedCommands
    | keyof MobileSupportedCommands
    | keyof DesktopSupportedCommands
    | 'addEntity';

export class ClientMessageSender {
    constructor(
        private o: AdDetails,
        private messagePort: MessagePort,
    ) {}
    sendMessage = <T extends (...args: any) => any>(command: ToHostCommandType, data?: Parameters<T>[1]) => {
        const msg: ClientMessageToHost = {
            command: command,
            arid: this?.o.arid,
            data: data || {},
        };

        try {
            this.messagePort.postMessage(msg);
        } catch (ex) {
            errorLog('Post Message error', ex as Error);
        }
    };
}
