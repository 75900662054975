// List of Pre-Defined CSM Counters to be used inside the safeframe.
export const IFRAME_INIT = 'adload:iframeinitialized';
export const RENDER_START = 'adload:renderstart';
export const RENDER_END = 'adload:renderend';
export const CREATIVE_WRITTEN = 'adload:creativewritten';
export const IMP = 'adload:impression';
export const UNSERVED_IMP = 'adload:unservedimpression';
export const VIEWABILITY = 'adload:viewability';
export const UNSERVED_VIEWABILITY = 'adload:unservedviewability';
export const MEASURABILITY = 'adload:measurability';

// These ones are used inside the host
export const START = 'adload:start';
export const CALLBACK = 'adload:delayloadcallback';
export const IFRAME_CREATED = 'adload:iframecreated';

export type AD_LOAD_COUNTERS =
    | typeof IFRAME_INIT
    | typeof RENDER_START
    | typeof RENDER_END
    | typeof CREATIVE_WRITTEN
    | typeof IMP
    | typeof UNSERVED_IMP
    | typeof VIEWABILITY
    | typeof UNSERVED_VIEWABILITY
    | typeof MEASURABILITY
    | typeof START
    | typeof CALLBACK
    | typeof IFRAME_CREATED;
