import type { ViewableInfo } from '@amzn/safe-frame-client-amzn';
import { InternalSFClientAPI } from '../client/InternalSFClientAPI';

/*
 * We no longer trigger pixel tracking URLs by appending the img to the DOM.
 * We use a made up attribute 'pixel-url' to store the URL for testing/debugging.
 * We will directly call the pixel URL with the fetch API.
 */
export const createPixel = (id: string, pixelUrl: string): HTMLImageElement => {
    const pixel = document.createElement('img');
    pixel.id = id;
    pixel.style.display = 'none';
    pixel.setAttribute('pixel-url', pixelUrl);
    return pixel;
};

export const formFullCounterName = (baseCounterName: string, eventName: string, extension: string | undefined) => {
    let finalCounter = baseCounterName + eventName;
    if (extension) {
        finalCounter += '_' + extension;
    }
    return finalCounter;
};

export const isPixelFired = (id: string): boolean => !!document.getElementById(id);

export type PixelData = {
    id: string;
    pixelUrl: string;
    baseCounterName: string;
    baseMetricName: string;
    counterExtension?: string;
};

export interface ViewablePixelTrace {
    viewableInfo: ViewableInfo;
    aaxInstrPixelUrl: string;
    viewablePercentage: number | null;
}

/**
 * Fires pixel via initiating an image request
 * @param {Object} messengerClient - safeframe messenger client
 * @param {string} pixelUrl - url of pixel to be fired
 * @param {string} id - pixel id in DOM
 * @param {string} baseCounterName - base CSM counter name
 * @param {string} baseMetricName - base CSA metric name
 * @param {string} counterExtension - extension to CSM counters that are fired
 * @param {Object} additionalTrace - for adBar logging
 * @returns {boolean} indicates if pixel was fired.  Will be false if already fired
 */
export const firePixel = async (
    c: InternalSFClientAPI,
    { pixelUrl, id, baseCounterName, baseMetricName, counterExtension }: PixelData,
    additionalTrace?: ViewablePixelTrace,
): Promise<boolean> => {
    try {
        if (isPixelFired(id)) return false;

        c.logCsaEvent(formFullCounterName(baseMetricName, 'fired', counterExtension));
        c.countMetric(formFullCounterName(baseCounterName, 'fired', counterExtension), 1);
        c.sendAdBarTrace('pixels', { fired: { pixelUrl: pixelUrl, additionalTrace: additionalTrace } });

        await fetch(pixelUrl, { keepalive: true, mode: 'no-cors', priority: 'low' } as RequestInit);
        document.getElementsByTagName('body')[0].appendChild(createPixel(id, pixelUrl));

        c.countMetric(formFullCounterName(baseCounterName, 'loaded', counterExtension), 1);
        c.logCsaEvent(formFullCounterName(baseMetricName, 'loaded', counterExtension));
        c.sendAdBarTrace('pixels', {
            loaded: { pixelUrl: pixelUrl, additionalTrace: additionalTrace },
        });
        return true;
    } catch (ex) {
        c.countMetric(formFullCounterName(baseCounterName, 'error', counterExtension), 1);
        c.logCsaEvent(formFullCounterName(baseMetricName, 'error', counterExtension));
        c.sendAdBarTrace('pixels', { error: { pixelUrl: pixelUrl, additionalTrace: additionalTrace } });
        c.logError(`Error firing pixels: ${pixelUrl}`, ex as Error);
        throw ex;
    }
};

export const buildPixelUrl = (baseUrl: string, ext: string, params: object) => {
    // TODO: This is not fault tolerant, relies on correct format of '/'
    return baseUrl + ext + JSON.stringify(params);
};
