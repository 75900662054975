import { DesktopSFClientAPIImplementation } from './DesktopSFClientAPIImplementation';
import { ClientMessageSender } from './clientMessageSender';
import type { AdDetails } from '../../@types/adCommon';
import { CommonSFClientSetup } from './CommonSFSetup';
import { SetupVideoPlayer } from './video';

export class SFClientSetup extends CommonSFClientSetup<DesktopSFClientAPIImplementation> {
    constructor(o: AdDetails, messagePort: MessagePort, setupVideoPlayer?: SetupVideoPlayer) {
        const cms = new ClientMessageSender(o, messagePort);
        const mL = {};
        super(
            o,
            messagePort,
            mL,
            cms,
            new DesktopSFClientAPIImplementation(cms, o, mL, () => this.forceRenderFallbackExperience()),
            setupVideoPlayer,
        );
    }
}
