import { CRITICAL_FEATURE, LOADED } from '../components/metrics/latency-metric-type';
import { CommonApiImplementation } from './CommonApiImplementation';

export const disableCookieAccess = () => {
    Object.defineProperty(document, 'cookie', {
        get: () => {
            return '';
        },
        set: () => {
            /** Empty */
        },
    });
};

export const disableGeolocationApi = () => {
    if (window?.navigator?.geolocation) {
        window.navigator.geolocation.watchPosition = (
            _successCallback: PositionCallback,
            _errorCallback?: PositionErrorCallback | null,
            _options?: PositionOptions,
        ): number => {
            return 0; /* Empty */
        };
        window.navigator.geolocation.getCurrentPosition = () => {
            /** Empty */
        };
    }
};

/*
 * Use for cases where safeframe is not supported and
 * error message could not possibly propagate to parent
 * window for proper RTLA logging.
 */

/**
 * @deprecated Use logError
 */
export const errorLog = (msg: string, ex?: Error | string) => {
    /* eslint-disable no-console */
    console.error(msg, ex);
    /* eslint-enable no-console */
};

/**
 * We want to avoid spiking TM99 TTI on with highly contented pages.
 * So we look at the script loading time.  If it greater than a arbitrary time
 * we back off trying to load the rest of the ad and collapse it.
 * This saves the bandwidth to load the ad and the processing time for the DOM.
 */
export const checkIfSlowLoadedScript = (): boolean => {
    const MAX_LOADING_TIME_IN_MS = 2000;
    // This assumes that the sf client is being loaded via the <script> tag in the client html and is first
    // e.g. https://images-na.ssl-images-amazon.com/images/S/apesafeframe/ape-dev/sf/desktop/sf-1.50.1a077cb5.js
    const isTooLong = (entry: PerformanceEntry, _index: number, _obj: PerformanceEntry[]): boolean => {
        return (
            (entry as PerformanceResourceTiming).initiatorType === 'script' && entry.duration > MAX_LOADING_TIME_IN_MS
        );
    };
    return performance?.getEntriesByType?.('resource')?.findIndex?.(isTooLong) > -1;
};

export const sendCriticalFeatureAndLoaded = (c: CommonApiImplementation) => {
    c.sendLatencyMetric(LOADED);
    c.sendLatencyMetric(CRITICAL_FEATURE);
};
